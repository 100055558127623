<template>
  <div class="fullScreen show" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        新增劳动合同
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain">
      <el-tabs class="crad" type="border-card">
        <el-tab-pane label="新增劳动合同">
          <div class="projectSubitem" v-loading="loading">
            <UploadList v-model="uploadData" fileCode="agreement_fj"></UploadList>
            <div class="fullScreenOperation">
              <el-button type="success" :loading="saveloading" @click="save">
                保存
              </el-button>
              <el-button type="info" @click="add_re">返回</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
    UploadList: () => import("./AddUploadList.vue"),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: {},
      loading: false,
      saveloading: false,
      uploadData: [],
    };
  },
  inject: ["add_re"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function(newVal) {
        // this.$emit('update:loading', false);
        this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function(newVal, oldVal) {
        this.form = newVal;
      },
    },
  },
  created() {},
  methods: {
    save() {
      if (
        this.uploadData.some((r) => {
          return r.loading;
        })
      ) {
        this.$message.warning("文件上传中，请稍后再试！");
        return "";
      }
      this.uploadData[0].staffId = this.form.id;
      this.saveloading = true;
      let arr = [this.uploadData[0]];
      this.$api.user
        .addLaborContract(arr)
        .then((res) => {
          //只能传入 一份劳动合同
          this.saveloading = false;
          this.$message.success("操作成功！");
          this.add_re();
        })
        .catch((err) => {
          console.log(err);
          this.saveloading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/edit.scss";
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
</style>
